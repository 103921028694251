import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex items-center justify-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    width: "40%",
    center: "",
    "before-close": $setup.handleClose
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("h1", { class: "text-center text-4xl font-bold" }, "Change Password", -1)
    ])),
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        as: "el-form",
        "label-position": "top",
        "validation-schema": $setup.schema,
        "initial-values": $setup.initialValues,
        onSubmit: $setup.onSubmit
      }, {
        default: _withCtx(({ isSubmitting }) => [
          _createElementVNode("div", {
            class: _normalizeClass(['justify-center', isSubmitting && 'isSubmitting-rounded'])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode($setup["Field"], { name: "CurrentPassword" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Current password*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        type: "password",
                        placeholder: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode($setup["Field"], { name: "NewPassword" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "New password*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        type: "password",
                        placeholder: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode($setup["Field"], { name: "ConfirmPassword" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Confirm new password*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        type: "password",
                        placeholder: ""
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ], 2),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn--gray",
              onClick: $setup.handleClose
            }, " Cancel "),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--red-primary",
              disabled: isSubmitting
            }, " Update ", 8, _hoisted_3)
          ])
        ]),
        _: 1
      }, 8, ["validation-schema", "initial-values"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}