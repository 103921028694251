import { defineComponent as _defineComponent } from 'vue'
import AccountModule from "@/store/modules/Account";
import DialogModule from "@/store/UI/Dialog";
import DiaLogUpdatePassword from "@ems/containers/Profile/Dialog.UpdatePasswordAccount.vue";
import { Field, Form } from "vee-validate";
import { computed } from "vue";
import { number, object, string } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'Profile',
  setup(__props, { expose: __expose }) {
  __expose();

const userInfo = computed(() => AccountModule.dataAccountProfileGetter);
const schema = object({
  FirstName: string().required().label("First Name"),
  LastName: string().required().label("Last Name"),
  Email: string().required().email().label("Email"),
  PhoneNumber: number().required().label("Phone Number"),
});

const onSubmit = async (values: any) => {
  await AccountModule.updateUserProfile(values);
};

const handleOpenModal = () => {
  DialogModule.showModal({
    component: DiaLogUpdatePassword,
  });
};

const __returned__ = { userInfo, schema, onSubmit, handleOpenModal, get AccountModule() { return AccountModule }, get DialogModule() { return DialogModule }, DiaLogUpdatePassword, get Field() { return Field }, get Form() { return Form }, computed, get number() { return number }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})