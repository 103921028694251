import { defineComponent as _defineComponent } from 'vue'
import AccountModule from "@/store/modules/Account";
import DialogModule from "@/store/UI/Dialog";
import { Field, Form } from "vee-validate";
import { computed } from "vue";
import { object, ref as yupRef, string } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog.UpdatePasswordAccount',
  setup(__props, { expose: __expose }) {
  __expose();

const initialValues = computed(() => DialogModule.getData || {});

const schema = object({
  CurrentPassword: string().required("Password is required").min(6),
  NewPassword: string().required("New Password is required").min(6),
  ConfirmPassword: string()
    .required("Confirm Password is required")
    .oneOf([yupRef("NewPassword"), null], "Passwords must match"),
});

const onSubmit = async (values: any, actions: any) => {
  await AccountModule.changePassword(values);

  if (AccountModule.isError) {
    actions.setFieldError("CurrentPassword", "Your password is invalid");
  } else {
    DialogModule.hideModal();
  }
};

const handleClose = () => {
  DialogModule.hideModal();
};

const __returned__ = { initialValues, schema, onSubmit, handleClose, get AccountModule() { return AccountModule }, get DialogModule() { return DialogModule }, get Field() { return Field }, get Form() { return Form }, computed, get object() { return object }, get yupRef() { return yupRef }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})